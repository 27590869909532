#canvas-container {
    height: 200px;
    width: auto;
    position: relative;
}

@media (min-width: 768px) {
    #canvas-container {
        height: auto;
        width: auto;
    }
}
