.icheckbox {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 1px solid #aaa;
    float: right;
}

.icheckbox.checked {
    background: #ccc;
}

@media (min-width: 480px) {
    .icheckbox {
        float: none;
    }
}

@import 'https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic&subset=latin,cyrillic';

*,
*:after,
*:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.responsive-table-input-matrix {
    margin: 1em 0;
    width: 100%;
}

@media (min-width: 480px) {
    .responsive-table-input-matrix .responsive-table-input-matrix {
        width: auto;
    }
}

.responsive-table-input-matrix tr {
    border: none;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    /* display: block; */
    border-radius: 2px;
    margin-bottom: 1.6rem;
    background-color: #fff;
    /* padding: 0.4rem 0 0.8rem; */
}

.responsive-table-input-matrix tr:first-of-type {
    display: none;
}

.responsive-table-input-matrix tr td {
    text-align: left;
}

@media (min-width: 480px) {
    .responsive-table-input-matrix tr:first-of-type {
        display: table-row;
    }

    .responsive-table-input-matrix tr td {
        text-align: center;
    }

    .responsive-table-input-matrix tr {
        -webkit-box-shadow: 0;
        -moz-box-shadow: 0;
        box-shadow: 0;
        display: table-row;
        border: 1px solid #e0e0e0;
    }
}

.responsive-table-input-matrix tr td:first-of-type {
    text-align: left;
}

.responsive-table-input-matrix tr td:first-of-type:before {
    width: 0;
}

.responsive-table-input-matrix th {
    display: none;
}

.responsive-table-input-matrix td {
    display: flex;
    /* vertical-align: middle; */
    text-align: right;
}

/* .responsive-table-input-matrix td:first-child {
    padding-top: 0.5em;
}

.responsive-table-input-matrix td:last-child {
    padding-bottom: 0.5em;
} */

.responsive-table-input-matrix td:before {
    content: attr(data-th);
    font-size: inherit;
    font-weight: 600;
    color: #757575;
    width: 50%;
    /* display: inline-block; */
}

.responsive-table-input-matrix td input[type='checkbox'] {
    /* float: right; */
    /* align checkbox to the right */
    /* margin: 0.5em 0; */
    margin-left: 10em;
}

@media (min-width: 480px) {
    .responsive-table-input-matrix td:before {
        display: none;
    }

    .responsive-table-input-matrix td input[type='checkbox'] {
        float: none;
        margin: 0em;
    }
}

.responsive-table-input-matrix label {
    /* padding-left: 5em;
    padding-top: 0.2em;
    padding-bottom: 0.2em; */
    /* margin-left: 1em; */
    padding: 0.7em;
    /* width: 40%; */
    /* background-color: green; */
    height: 100%;
    cursor: pointer;
    display: flex;
}

.responsive-table-input-matrix th:first-of-type {
    text-align: left;
}

.responsive-table-input-matrix th,
.responsive-table-input-matrix td {
    text-align: center;
    background-color: #fff;
}

@media (min-width: 480px) {
    .responsive-table-input-matrix th,
    .responsive-table-input-matrix td {
        display: table-cell;
        /* padding: 0.25em 0.5em; */
    }

    .responsive-table-input-matrix th:first-child,
    .responsive-table-input-matrix td:first-child {
        padding: 0.7em;
    }

    .responsive-table-input-matrix th:last-child,
    .responsive-table-input-matrix td:last-child {
        padding-right: 0;
    }

    .responsive-table-input-matrix label {
        display: block;
    }
}

tbody {
    /* padding: 0 2em; */
    color: #212121;
    background-color: #f5f5f5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

.responsive-table {
    color: #333;
    border-spacing: 0;
    background-color: rgba(0, 0, 0, 0);
}

.responsive-table th,
.responsive-table td {
    margin: 0em 1em;
}

.shadow-z-1 {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

@media (min-width: 480px) {
    .shadow-z-1 {
        -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
        -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    }
}

@media (min-width: 480px) {
    .responsive-table {
        background: #fff;
    }

    /* .responsive-table th,
    .responsive-table td {
        padding: 1em !important;
    } */
}

.responsive-table th {
    padding: 0.7em;
    /* font-weight: 400; */
    /* color: #757575; */
    vertical-align: bottom;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.responsive-table th:last-child {
    padding: 0.7em;
    /* font-weight: 400; */
    /* color: #757575; */
    vertical-align: bottom;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
